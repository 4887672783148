import React from "react"; 
import AppHome from "../Views/Home";
import Services from "../Components/Home/Services";
import About from "../Components/Home/About";
import AppFooter from "../Components/Home/Footer";
import { Layout } from 'antd';
const { Content, Footer } = Layout;


function AppContent() {
    return(
        <>
         <Layout className="mainLayout">
         <Content>
          <AppHome />
          <Services />
          <About />
        </Content>
         <Footer>
          <AppFooter />
        </Footer>
      </Layout>
        </>
    );
}

export default AppContent;