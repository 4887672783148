import React from "react";

function About() {
    return(
        <div id="about" className="block aboutBlock">
            <div className="container-fluid">
                <div className="titleHolder">
                    <h2>About @ Tetra Silicon</h2>
                </div>
                <div className="contentHolder">
                    <p className="about-content">Tetra Silicon focuses on End to End Solutions and Services in Semiconductor, Embedded systems and Software Development with ever-changing technology. Our design thinking based on development approach encompasses different engagement models and it is tailored to meet even the most challenging customer needs. We started a vision of becoming a global leader in providing  Semiconductor, embedded systems and Software Development. Semiconductor industry pioneering in some of the visionary business models in the VLSI design services.</p>
                </div>
            </div>
        </div>
    );
}
export default About;