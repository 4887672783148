import React from "react";
import imageSemi from "../../assets/images/semiconductor.jpg";
import imageEmb from "../../assets/images/mama2.jpg";
import imageSoft from "../../assets/images/software.jpg";
import {Link} from "react-router-dom";

import { Row, Col, Card } from "antd";

const { Meta } = Card;

function Services() {
    return(
        <div id="services" className="block featureBlock bgGray">
            <div className="container-fluid">
                <div className="titleHolder">
                    <h2>Services</h2>
                </div>
                <Row gutter={[32, 32]}>
                    <Col md={{ span: 8}} sm={{ span: 12}}>
                        <Link to="/semiconductor">
                            <Card hoverable cover={<img alt="example" src={imageEmb} />} >
                                <Meta title="Semiconductor">
                                    
                                </Meta>
                            </Card>
                        </Link>
                    </Col>
                    <Col md={{ span: 8}} sm={{ span: 12}}>
                        <Link to="/embedded">
                        <Card hoverable cover={<img alt="example" src={imageSemi} />} >
                            <Meta title="Embedded Design">
                            </Meta>
                        </Card>
                        </Link>
                    </Col>
                    <Col md={{ span: 8}} sm={{ span: 12}} >
                        <Link to="/software">
                        <Card hoverable cover={<img alt="example" src={imageSoft} />} >
                            <Meta title="Software Development">
                            </Meta>
                        </Card>
                        </Link>
                    </Col>
                </Row>
                
            </div>
        </div>
    );
}

export default Services;
