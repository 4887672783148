import React,{useState} from "react";
import { Anchor, Drawer, Button  } from 'antd';
import {Link} from "react-router-dom";
import { SideNav } from "./SideNav";

// const { Link } = Anchor;

function Mobile() {
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
      setVisible(true);
    };
    const onClose = () => {
      setVisible(false);
    };
    return(
        <div className="mobileVisible">
        <Button type="primary" onClick={showDrawer}><i className="fas fa-bars"></i></Button>
        <Drawer  title="Mobile Nav" closable={false} onClose={onClose} visible={visible} >
        <Anchor targetOffset="65">
            {/* <Link to="/"> Home</Link> <br /><br />
            <Link to="/career"> Career</Link> <br /><br />
            <Link to="/semiconductor"> Semiconductor</Link><br /><br />
            <Link to="/embedded"> Embedded</Link><br /><br />
            <Link to="/software"> Software</Link> */}
            <nav>
              {/* <ul className="nav-menu-items">
                <li className="navbar-toggle">
                  <Link to="/" className="menu-bars">Home</Link>
                </li>
                <li className="navbar-toggle">
                  <Link to="/career" className="menu-bars">Careers</Link>
                </li>
              </ul> */}
              <ul className="nav-menu-items">
              {SideNav.map( (item,index) => {
                return(
                  <li key={index} className={item.name}>
                    <Link to={item.path}>
                      <span>{item.title}</span>
                    </Link>
                  </li>
                );
              })}
              </ul>
            </nav>    
       </Anchor>
        </Drawer>
    </div>
    );
}

export default Mobile;