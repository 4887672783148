export const SideNav = [
    {
        title: 'Home',
        path: '/',
        name: 'nav-text',
    },
    {
        title: 'Careers',
        path: '/career',
        name: 'nav-text',
    },
    {
        title: 'Semiconductor',
        path: '/semiconductor',
        name: 'nav-text',
    },
    {
        title: 'Embedded Design',
        path: '/embedded',
        name: 'nav-text',
    },
    {
        title: 'Software Development',
        path: '/software',
        name: 'nav-text',
    },
    {
        title: 'Contact Us',
        path: '/contact',
        name: 'nav-text',
    },
]