import React from "react"; 
import './App.css';
import AppHeader from "./Components/nav/AppHeader";
import Career from "./Components/Home/Career";
import Contact from "./Components/Home/Contact";
import {Switch, Route} from "react-router-dom";
import AppContent from "./Content/Content";
import Semiconductor from "./Components/ExtraPages/Semiconductor";
import Embedded from "./Components/ExtraPages/Embedded";
import Software from "./Components/ExtraPages/Software";

import 'antd/dist/antd.css';
import { Layout } from 'antd';
import ScrollToTop from "./Components/ExtraPages/ScrollToTop";

const { Header } = Layout;

function App() {
  return (
    <>
       <Layout className="mainLayout">
        <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
          <AppHeader />
        </Header>
        <Switch>
          <ScrollToTop>
            <Route exact path="/" component={AppContent} />
            <Route exact path="/career" component={Career} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/semiconductor" component={Semiconductor} />
            <Route exact path="/embedded" component={Embedded} />
            <Route exact path="/software" component={Software} />
          </ScrollToTop>
        </Switch>
      </Layout>
    </>
  );
}

export default App;

