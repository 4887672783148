import React from "react";
import { Carousel } from 'antd';

const sample = [
    {
        key: '1',
        title: "TETRA SILICON",
        content: "Next generation application.",
        name: "welcome"
    },
    {
        key: '2',
        title: "Semiconductor",
        content: "We provide quality engineering services in the area of ASIC Design/SoC Verification, Physical Design, Design for Testability (DFT), Design Verification & Validation.",
        name: ""
    },
    {
        key: '3',
        title: "Embedded",
        content: "We provide quality engineering services in the area of Embedded hardware and software for Industrial & IoT products. Design & development of high speed digital and mixed signal PCBs. Complex hardware and software development.",
        name: "carousel1"
    },
    {
        key: '4',
        title: "Software",
        content: "We provide quality Service in Web Development using modern Technologies. Automation and Manual Testing using Java.",
        name: "carousel2"
    },
]

function AppHero() {
    return(
        <div className="heroBlock">
            <Carousel autoplay="true">
                {
                sample.map(item => {
                    return(
                        <div key={item.key} className={item.name}>
                            <div className="container-fluid" key={item.key}>
                                <div className="content">
                                    <h3>{item.title}</h3>
                                    <p>{item.content}</p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
}
export default AppHero;
