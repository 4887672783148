import React from "react";
import AppHero from "../Components/Home/Hero";

function AppHome(){
    return(
        <div id="home" className="main">
            <AppHero />
        </div>
    );
}
export default AppHome;