import React,{useState} from "react";
import { Menu} from 'antd';
import Mobile from "../../Mobile/Mobile";
import logo from "../../assets/images/tetra.png"
import {Link} from 'react-router-dom';
const { SubMenu, Item } = Menu;


function AppHeader(){
   const [navbar, setNavbar] = useState(false);

   const changeBackgroung = () => {
      if(window.scrollY >= 70) {
         setNavbar(true);
      } else {
         setNavbar(false);
      }
   }
   window.addEventListener('scroll', changeBackgroung);
    return(
       <div className={navbar ? 'navbar active' : 'navbar'}>
        <div className="container-fluid">
            <div className="header">
            <div className="logo">
               <i ><img src={logo} alt="header-logo" className="logoimage"/></i>
               <a href="#">Tetra Silicon</a>
            </div>
            <div className="mobileHidden">
              <Menu mode="horizontal" defaultSelectedKeys={['home']}>
               <Item key="home"><Link to="/" style={{color: 'snow'}}>Home</Link>
                </Item>
                <SubMenu key="services" title="Our Services" style={{color: 'snow'}}>
                     <SubMenu key="semiconductor" title="Semiconductor">
                        <Item key="ASIC Design/SoC"><Link to="/semiconductor">ASIC Design/SoC Verification</Link></Item>
                        <Item key="Physical Design"><Link to="/semiconductor">Physical Design</Link></Item>
                        <Item key="Design for Testability"><Link to="/semiconductor">Design for Testability (DFT)</Link></Item>
                        <Item key="Design Verification"><Link to="/semiconductor">Design Verification & Validation</Link></Item>
                     </SubMenu>
                    <SubMenu key="embedded" title="Embedded">
                       <Item key="ProductDesign"><Link to="/embedded">Embedded Product Design</Link></Item>
                       <Item key="SoftwareDevelopment"><Link to="/embedded">Embedded Software Development</Link></Item>
                    </SubMenu>
                    <SubMenu key="software" title="Software">
                       <Item key="Web"><Link to="/software">Web Development</Link></Item>
                       <Item key="Testing"><Link to="/software">Automation/Manual Testing</Link></Item>
                    </SubMenu>
                </SubMenu>
                <Item key="career"> <Link to="/career" style={{color: 'snow'}}>Career</Link>
                </Item>
                <Item key="contact"><Link to="/contact" style={{color: 'snow'}}>Contact Us</Link>
                </Item>
                </Menu>
                </div>
                <Mobile />
            </div>
        </div>
        </div>
    );
}
export default AppHeader;
