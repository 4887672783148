import React, { useEffect } from "react";
import { Row, Col, Card } from 'antd';
import imageWeb from "../../assets/images/mern-stack.jpg";
import imageTesting from "../../assets/images/Testing.jpg";
import { Carousel } from 'antd';
import { TimelineLite, Power3 } from "gsap/all";
const { Meta } = Card;

const sample = [
    {
        key: '1',
        title: "Software Development",
        name: "softwarepage",
    },
   
]
const items = [
    {
        key: '1',
        title: "Website Development",
        content: "Web development services with advanced development technologies.",
        icon: <i className="fas fa-desktop"></i>,
    },
    {
        key: '2',
        title: "Testing",
        content: "Testing services with modern and feature-rich solutions.",
        icon: <i className="fas fa-cogs"></i>,
    },
    {
        key: '3',
        title: "E-Commerce",
        content: "Ecommerce solutions with latest technologies.",
        icon: <i className="fas fa-chart-pie"></i>,
    },
   
]


function Software(){
    useEffect( () => {
        let animation = new TimelineLite({ delay: 0.3});
        animation.staggerFrom('.contentHolder', 1, {y: 50, ease: Power3.easeOut, opacity: 0 }, 0.15, 'start')
        animation.staggerFrom('.softwarepage', 1, {y: 50, ease: Power3.easeOut, opacity: 0 }, 0.15, 'start')
        .from(".software-row", { y: 30, opacity: 0, ease: Power3.easeOut, delay: 0.2}, 0.35, 'start');
    }, [])
    return(
        <>
        <Carousel>
               {
               sample.map(item => {
                   return(
                       <div key={item.key} className={item.name}>
                           <div className="container-fluid" key={item.key}>
                               <div className="content">
                                   <h3>{item.title}</h3>
                               </div>
                           </div>
                       </div>
                   );
               })}
           </Carousel>
        <div id="software" className="block softwareBlock">
            <div className="container-fluid">
                <div className="contentHolder">
                    <p className="software-content">Tetra Silicon is proud to have smart working engineers with expertise in various domains. We provide quality engineering services in the area of Web Development and Testing.</p>
                </div>
                <Row gutter={[48, 32]} className="software-row">
                    <Col lg={{ span: 12}} md={{ span: 8}} sm={{ span: 12}}>
                        <Card hoverable cover={<img alt="example" src={imageWeb} className="software-card width-card" />} >
                        <Meta title="Web Development" />
                        </Card>
                     </Col>
                     <Col lg={{ span: 12}} md={{ span: 8}} sm={{ span: 12}}>
                        <Card hoverable cover={<img alt="example" src={imageTesting} className="software-card" />} >
                        <Meta title="Testing" />
                        </Card>
                     </Col>
                </Row>
                <div className="software-collapse">
                <div className="titleHolder">
                    <h2>Web Services</h2>
                </div>
                <Row gutter={[16, 16]}>
                    {items.map(item => {
                        return(
                            <Col md={{ span: 8}} sm={{ span: 12}} key={item.key}>
                                <div className="content">
                                    <div className="icon">
                                        {item.icon}
                                    </div>
                                    <h3>{item.title}</h3>
                                    <p>{item.content}</p>
                                </div>
                            </Col>
                        );
                    })}
                  
                </Row>
                </div>
            </div>
        </div>
        </>
    );
}

export default Software;